/* eslint-disable @typescript-eslint/no-explicit-any */
import { lazy, Suspense, useEffect, useState } from 'react';
import { EditableComponent, ResponsiveGrid } from '@adobe/aem-react-editable-components';
import clsx from 'clsx';

import { AEMReactCompMap, Heading, Types, AccordionEnhanced, Button } from '@marriott/mi-ui-library';
import { removeParentTagRTE } from '@marriott/mi-ui-library';
import { CqItems, ChildComponentProps, HeaderListItemProps } from './AccordionContainerEnhanced.types';
import { StyledAccordionContainerEnhanced } from './AccordionContainerEnhanced.styles';

export const AccordionContainerConfig = {
  emptyLabel: 'Accordion Container Enhanced',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/content/accordioncontainerenhanced`,
};

export const AccordionContainerEnhanced = (props: any) => {
  return (
    <EditableComponent config={AccordionContainerConfig} {...props}>
      <AccordionContainerEnhancedComp {...props} />
    </EditableComponent>
  );
};

export const AccordionContainerEnhancedComp = (props: any) => {
  const { trackingProperties, componentId, styleclass } = props.model;
  const customAttributes = trackingProperties?.enableScrollingBehavior ? { 'data-section-tracking': componentId } : {};

  return styleclass?.includes('fullbleed') ? (
    <div
      className={clsx(styleclass, 'm-container-fullbleed')}
      data-testid="accordion-container"
      data-component-name="o-common-static-accordioncontainerenhanced"
    >
      <div {...customAttributes} className="container">
        <AccordionMarkup {...props} />
      </div>
    </div>
  ) : (
    <div
      {...customAttributes}
      className={clsx(styleclass, 'container')}
      data-testid="accordion-container"
      data-component-name="o-common-static-accordioncontainerenhanced"
    >
      <AccordionMarkup {...props} />
    </div>
  );
};

const AccordionMarkup = (props: any) => {
  const {
    headerListItems,
    rootHeaderText,
    rootHeaderTag,
    headerAlignment,
    styleclass,
    showExpandCollapseAllControl,
    activateDrawerBehavior,
  } = props.model;

  const [isOpen, setIsOpen] = useState(false);
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [allOpen, setAllOpen] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const isTextCenterAligned = headerAlignment === 'center';
  const accordionThemeStyleClass = styleclass?.includes('standard')
    ? 'standard'
    : styleclass?.includes('inverse')
    ? 'inverse'
    : styleclass?.includes('alternate')
    ? 'alternate'
    : '';
  const accordionCoralIconTheme = styleclass?.includes('icon-decorative') ? 'icon-decorative' : '';

  const totalNumberOfCards = headerListItems.length;

  const mapper = AEMReactCompMap(props?.allowedComponents);

  const isHeader = rootHeaderText?.length > 0;
  // Using removeParentTagRTE to clean the text recieved from RTE
  const rootHeaderRTEText =
    isHeader && (rootHeaderText.includes('<') || rootHeaderText.includes('>'))
      ? removeParentTagRTE(rootHeaderText)
      : rootHeaderText;

  useEffect(() => {
    const pageWcmMode = document.getElementsByTagName('html')[0];
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.attributeName === 'class') {
          const currWcmMode = pageWcmMode?.getAttribute('class');
          if (currWcmMode?.includes('Edit')) {
            setIsOpen(true);
            setEditMode(true);
          } else {
            setIsOpen(false);
            setEditMode(false);
          }
        }
      });
    });
    observer.observe(pageWcmMode, { attributes: true });
    return () => {
      observer.disconnect();
    };
  }, []);

  const AccordionComp = (
    comp: () => JSX.Element,
    idx: number,
    headerListItem: HeaderListItemProps,
    styleClass: string,
    childrenStyleClass: string,
    isOpen: boolean
  ) => {
    const headerName = headerListItem?.header || '';
    // Using removeParentTagRTE to clean the text recieved from RTE
    const headerRTEText =
      headerName.includes('<') || headerName.includes('>') ? removeParentTagRTE(headerName) : headerName;

    const handleAccordionClick = (idx: number) => {
      if (props.model.activateDrawerBehavior) {
        if (openIndex === idx) {
          setOpenIndex(null);
        } else {
          setOpenIndex(idx);
        }
      }
    };

    return (
      <AccordionEnhanced
        styleclass={styleClass}
        headerChildren={
          <Heading
            dangerouslySet={headerRTEText}
            element={headerListItem?.drawerHeaderTag || Types.tags.h2}
            variation={Types.headingType.body}
            fontSize={Types.size.medium}
            customClass="m-accordion__title mb-0 text-left m-ellipsis-1line"
          />
        }
        id={`${props.model.componentId}_${idx}`}
        isOpen={
          isEditMode
            ? true
            : showExpandCollapseAllControl
            ? allOpen
            : activateDrawerBehavior
            ? idx === openIndex
            : isOpen
        }
        openIndex={openIndex}
        onAccordionClick={() => {
          handleAccordionClick(idx);
        }}
        assetVariation={headerListItem?.assetVariation}
        iconPath={headerListItem?.iconPath}
        dynamicMedia={headerListItem?.dynamicMedia}
        childrenClass={childrenStyleClass.length > 0 ? childrenStyleClass : accordionThemeStyleClass}
      >
        {comp()}
      </AccordionEnhanced>
    );
  };

  const collapseExpandButton = () => {
    const showCollapseButton = () => (
      <Button
        href="javascript:void(0)"
        isLink={true}
        callback={() => setAllOpen(!allOpen)}
        buttonCopy={allOpen ? 'Collapse All' : 'Expand All'}
        className={[
          `m-link t-font-m p-0 mb-3 expand-collapse-btn align-self-end ${isTextCenterAligned ? 'mb-3' : 'mb-4'}`,
        ]}
      />
    );

    return (
      showExpandCollapseAllControl &&
      (isTextCenterAligned ? (
        <div className="d-flex justify-content-end mt-2">{showCollapseButton()}</div>
      ) : (
        showCollapseButton()
      ))
    );
  };

  const isAuthorAccordionComponent = (idx: number, headerListItem: HeaderListItemProps) => {
    const AEMContainer = () => {
      return (
        <ResponsiveGrid
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          pagePath={props?.model?.pagePath}
          itemPath={`${props?.model?.itemPath}/${headerListItem?.nodeName}`}
          customClassName={clsx('p-0')}
          config={{
            isEmpty: () => true,
            resourceType: 'mi-aem-common-spa/components/container',
          }}
        />
      );
    };
    return AccordionComp(
      AEMContainer,
      idx,
      headerListItem,
      accordionThemeStyleClass + ' ' + accordionCoralIconTheme,
      '',
      isOpen
    );
  };

  const isNonAuthorModeAccComp = (
    headerListItem: HeaderListItemProps,
    idx: number,
    jsonData: CqItems
  ): React.ReactNode => {
    const headerItem = headerListItem?.nodeName || '';
    if (Object.prototype.hasOwnProperty.call(jsonData, headerItem) && headerListItem) {
      const card = jsonData[headerItem] as ChildComponentProps;
      const cardItems = card[':items'];
      const cardType = card[':type'];
      if (cardType.includes('nt:unstructured')) {
        const accArgs = () => <></>;
        return <Suspense fallback={<></>}>{AccordionComp(accArgs, idx, headerListItem, '', '', false)}</Suspense>;
      }
      for (const itemKey in cardItems) {
        if (Object.prototype.hasOwnProperty.call(cardItems, itemKey)) {
          const item = cardItems[itemKey];
          const itemType = item[':type']?.split('/').pop();
          const childrenStyleClass = item?.styleclass || '';
          if (Object.prototype.hasOwnProperty.call(mapper, itemType)) {
            const innerComp = mapper[itemType];
            const Component = lazy(() =>
              import(`../${innerComp}/index`).then((module: any) => ({ default: module[`${innerComp}`] }))
            );
            const itemProps = { model: { ...item }, ...item };
            const accArgs = () => <Component {...itemProps} />;
            return (
              <Suspense fallback={<></>}>
                {AccordionComp(
                  accArgs,
                  idx,
                  headerListItem,
                  accordionThemeStyleClass + ' ' + accordionCoralIconTheme,
                  childrenStyleClass,
                  false
                )}
              </Suspense>
            );
          }
        }
      }
    }
    return null;
  };

  return (
    <StyledAccordionContainerEnhanced
      showExpandCollapseAllControl={showExpandCollapseAllControl}
      isTextCenterAligned={isTextCenterAligned}
    >
      <>
        <div
          className={clsx(
            'd-flex align-items-center',
            isTextCenterAligned ? 'justify-content-center' : 'justify-content-between'
          )}
        >
          {isHeader && (
            <Heading
              dangerouslySet={rootHeaderRTEText}
              element={rootHeaderTag || Types.tags.h2}
              variation={
                props?.model?.rootHeaderFontStyle?.split('-')[1] as
                  | Types.headingType.body
                  | Types.headingType.subtitle
                  | Types.headingType.title
              }
              titleText={props?.model?.rootHeaderText || ''}
              fontSize={
                props?.model?.rootHeaderFontStyle?.split('-')[2] as
                  | Types.size.medium
                  | Types.size.large
                  | Types.size.small
              }
              customClass={clsx(
                'm-accordion__container_header',
                isTextCenterAligned ? 'text-center' : 'text-left flex-fill mr-4'
              )}
            />
          )}
          {!isTextCenterAligned && collapseExpandButton()}
        </div>
        {isTextCenterAligned && collapseExpandButton()}
        {props?.isAuthorMode
          ? Array.from({ length: totalNumberOfCards }, (_, i) => isAuthorAccordionComponent(i, headerListItems[i]))
          : headerListItems?.map((headerItem: HeaderListItemProps, idx: number) =>
              isNonAuthorModeAccComp(headerItem, idx, (props.model?.cqItems || props.model?.[':items']) as CqItems)
            )}
      </>
    </StyledAccordionContainerEnhanced>
  );
};
