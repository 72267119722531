import React, { FC, useRef, useEffect, useState, RefObject } from 'react';
import clsx from 'clsx';

import { BannerTextProps } from './BannerText.types';
import { StyledBannerText } from './BannerText.styles';
import { Eyebrow } from '../../atoms/Eyebrow';
import { Icon } from '../../atoms/Icon';
import { Heading } from '../../atoms/Heading';
import { Link } from '../../atoms/Link';
import { Image } from '../../atoms/Image';
import { Button } from '../../atoms/Button';
import { headingType, size, expanded_constants } from '../../utils/enums/enums';
import { throttle } from '../../utils/helper';
import { RuleLine } from '../../atoms/RuleLine';

export const BannerText: FC<BannerTextProps> = (props: BannerTextProps) => {
  const {
    assetVariation,
    contentBlockAlignment,
    eyebrow,
    headerText,
    headerTag,
    headerFontSize,
    horizontalRule,
    descriptionText,
    showShortDescription,
    ctaLink,
    ctaLinkText,
    ctaType1 = 'primaryButton',
    openInNewTab = false,
    openInNewTab2 = false,
    iconPath,
    iconAltText,
    dynamicMedia,
    trackingProperties,
    styleclass,
    componentId,
    ctaLinkText2,
    ctaLink2,
    ctaType2 = 'secondaryButton',
    descriptionLayout = 'standard',
    column1ShowShortDescription,
    column2CtaType,
    column1CtaType,
    column2OpenInNewTab,
    column2ShowShortDescription,
    column1OpenInNewTab,
    column2DescriptionText,
    column2CtaLink,
    column1CtaLink,
    column2CtaLinkText,
    column1DescriptionText,
    column1CtaLinkText,
    horizontalRulePlacement = 'belowHeader',
  } = props;

  const renditions = dynamicMedia?.renditions;
  const BannerTextDescriptionRef = useRef<HTMLDivElement>(null);
  const Column1DescriptionRef = useRef<HTMLDivElement>(null);
  const Column2DescriptionRef = useRef<HTMLDivElement>(null);
  const customAttributes = trackingProperties?.enableScrollingBehavior ? { 'data-section-tracking': componentId } : {};

  const [viewPort, setViewPort] = useState('Desktop');
  const isDesktopView = viewPort === 'Desktop';

  const handleResize = () => {
    const xs = window.matchMedia('(max-width: 767px)');
    const md = window.matchMedia('(min-width: 768px) and (max-width: 1199px)');
    const lg = window.matchMedia('(min-width: 1200px)');
    if (xs?.matches) {
      setViewPort('Mobile');
    } else if (md?.matches) {
      setViewPort('Tablet');
    } else if (lg?.matches) {
      setViewPort('Desktop');
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const ELLIPSIS_2LINESHEIGHT = 48;
  const { SEE_LESS, SEE_MORE } = expanded_constants;
  const [showLinkText, setShowLinkText] = useState(SEE_MORE);
  const [isShowMoreLinkHide, setIsShowMoreLinkHide] = useState(showShortDescription);
  const [column1ShowLinkText, setColumn1ShowLinkText] = useState(SEE_MORE);
  const [isColumn1ShowMoreLinkHide, setIsColumn1ShowMoreLinkHide] = useState(column1ShowShortDescription);
  const [column2ShowLinkText, setColumn2ShowLinkText] = useState(SEE_MORE);
  const [isColumn2ShowMoreLinkHide, setIsColumn2ShowMoreLinkHide] = useState(column2ShowShortDescription);

  function handleExpandButton(e: Event) {
    e.preventDefault();
    setShowLinkText(prevText => (prevText === SEE_LESS ? SEE_MORE : SEE_LESS));
  }

  function handleExpandButtonColumn1(e: Event) {
    e.preventDefault();
    setColumn1ShowLinkText(prevText => (prevText === SEE_LESS ? SEE_MORE : SEE_LESS));
  }

  function handleExpandButtonColumn2(e: Event) {
    e.preventDefault();
    setColumn2ShowLinkText(prevText => (prevText === SEE_LESS ? SEE_MORE : SEE_LESS));
  }

  const isEllipsisAdded = (ref: RefObject<HTMLDivElement>) => {
    if (ref.current) {
      return (
        ref.current.scrollHeight > ref.current.clientHeight ||
        !(ref.current.clientHeight <= ELLIPSIS_2LINESHEIGHT && ref.current.scrollHeight <= ELLIPSIS_2LINESHEIGHT)
      );
    }
    return false;
  };

  useEffect(() => {
    function handleSeeMoreOnResize() {
      if (showShortDescription) {
        if (BannerTextDescriptionRef.current) {
          setIsShowMoreLinkHide(isEllipsisAdded(BannerTextDescriptionRef));
        }
      } else {
        setIsShowMoreLinkHide(false);
      }
    }

    const throttledResizeHandler = throttle(handleSeeMoreOnResize, 500);
    const observer = new ResizeObserver(throttledResizeHandler);
    observer.observe(document.body);
    return () => observer.disconnect();
  }, [showShortDescription, showLinkText]);

  useEffect(() => {
    function handleSeeMoreOnResizeColumn1() {
      if (column1ShowShortDescription) {
        if (Column1DescriptionRef.current) {
          setIsColumn1ShowMoreLinkHide(isEllipsisAdded(Column1DescriptionRef));
        }
      } else {
        setIsColumn1ShowMoreLinkHide(false);
      }
    }

    const throttledResizeHandler = throttle(handleSeeMoreOnResizeColumn1, 500);
    const observer = new ResizeObserver(throttledResizeHandler);
    observer.observe(document.body);
    return () => observer.disconnect();
  }, [column1ShowShortDescription, column1ShowLinkText]);

  useEffect(() => {
    function handleSeeMoreOnResizeColumn2() {
      if (column2ShowShortDescription) {
        if (Column2DescriptionRef.current) {
          setIsColumn2ShowMoreLinkHide(isEllipsisAdded(Column2DescriptionRef));
        }
      } else {
        setIsColumn2ShowMoreLinkHide(false);
      }
    }

    const throttledResizeHandler = throttle(handleSeeMoreOnResizeColumn2, 500);
    const observer = new ResizeObserver(throttledResizeHandler);
    observer.observe(document.body);
    return () => observer.disconnect();
  }, [column2ShowShortDescription, column2ShowLinkText]);

  const brandCode = iconPath?.includes('brands') && `${iconPath.replace('brands/', '')}`;
  const brandPortfolioName =
    iconPath?.includes('portfolio') && `${iconPath.replace('brands/portfolio/portfolio-icon-', '')}`;

  const iconFontClasses = clsx({
    'icon-m': true,
    [`portfolio-icon-${brandPortfolioName}`]: brandPortfolioName,
    [`brand-logo-${brandCode}`]: !brandPortfolioName && brandCode,
    [`${iconPath} icon-size`]: !brandPortfolioName && !brandCode,
    'icon-decorative': styleclass?.includes('icon-decorative') && !brandPortfolioName && !brandCode,
  });

  const primaryCTAisLink = ctaLink ? true : false;
  const secondaryCTAisLink = ctaLink2 ? true : false;
  const column1CTAisLink = column1CtaLink ? true : false;
  const column2CTAisLink = column2CtaLink ? true : false;

  const buttonCallback = (link: string, openInNewTab: boolean) => {
    window.open(link, openInNewTab ? '_blank' : '_self');
  };

  function getCtaClasses(ctaType: string, openInNewTab: boolean) {
    let classNames = '';

    switch (ctaType) {
      case 'primaryButton':
        classNames += 'm-button-primary d-flex align-items-center';
        break;
      case 'secondaryButton':
        classNames += 'm-button-secondary d-flex align-items-center';
        break;
      case 'standaloneLink':
        classNames += 'm-link-action';
        break;
      case 'tertiaryLink':
        classNames += 'm-link-tertiary-button';
        break;
      default:
        break;
    }

    if (openInNewTab) {
      if (ctaType === 'primaryButton' || ctaType === 'secondaryButton') {
        classNames += ' m-button-external';
      } else {
        classNames += ' m-link-tertiary-button-external';
      }
    }

    return classNames.trim();
  }

  const renderButton = (
    link: string,
    linkText: string,
    isLink: boolean,
    openInNewTab: boolean,
    ctaType: string,
    trackingDescription: string | undefined
  ) => {
    return (
      link &&
      linkText && (
        <Button
          href={link}
          isLink={isLink}
          target={openInNewTab ? '_blank' : '_self'}
          callback={!isLink ? () => buttonCallback(link, openInNewTab) : undefined}
          className={getCtaClasses(ctaType, openInNewTab)}
          custom_click_track_value={`${trackingProperties?.location}|${trackingDescription}|${
            openInNewTab ? 'external' : 'internal'
          }`}
          buttonCopy={linkText}
          isTextBeforeChildren={false}
          linkAriaLabelOpenNewWindow="opens in new window"
        />
      )
    );
  };

  const ctaPrimary = () => {
    return renderButton(
      ctaLink as string,
      ctaLinkText as string,
      primaryCTAisLink,
      openInNewTab,
      ctaType1,
      trackingProperties?.trackingDescription
    );
  };

  const ctaSecondary = () => {
    return renderButton(
      ctaLink2 as string,
      ctaLinkText2 as string,
      secondaryCTAisLink,
      openInNewTab2,
      ctaType2,
      trackingProperties?.trackingDescription
    );
  };

  const ctaColumn1 = () => {
    return renderButton(
      column1CtaLink as string,
      column1CtaLinkText as string,
      column1CTAisLink,
      column1OpenInNewTab as boolean,
      column1CtaType as string,
      trackingProperties?.trackingDescription
    );
  };

  const ctaColumn2 = () => {
    return renderButton(
      column2CtaLink as string,
      column2CtaLinkText as string,
      column2CTAisLink,
      column2OpenInNewTab as boolean,
      column2CtaType as string,
      trackingProperties?.trackingDescription
    );
  };

  const Header = () => {
    if (!headerText) return null;
    return (
      <Heading
        element={headerTag}
        variation={headingType.title}
        titleText={headerText}
        fontSize={headerFontSize?.split('-')[2] as size.medium | size.small}
        disableCustomClickTrack
        customClass={clsx('bannertext_heading bannertext_text-align', 'm-0')}
      />
    );
  };

  const handleHorizontalRulePlacement = () => {
    if (!horizontalRule) return Header();

    if (horizontalRulePlacement === 'aboveHeader') {
      return (
        <>
          <RuleLine styleclass={styleclass} alignment={contentBlockAlignment} />
          {Header()}
        </>
      );
    } else {
      return (
        <>
          {Header()}
          <RuleLine styleclass={styleclass} alignment={contentBlockAlignment} />
        </>
      );
    }
  };

  const filteredClassNames = (styleclass || '')
    .split(' ')
    .filter((cls: string) => cls !== 't-horizontal-vertical-rule' && cls !== 't-accent-color')
    .join(' ');

  const isFullBleed = filteredClassNames.includes('m-container-fullbleed');

  return (
    <StyledBannerText
      data-component-name="m-ui-library-BannerText"
      data-testid="ui-library-BannerText"
      className={clsx(
        filteredClassNames,
        !isFullBleed && contentBlockAlignment === 'center' ? 'm-auto' : '',
        !isFullBleed && isDesktopView ? 'col-10' : ''
      )}
      contentBlockAlignment={contentBlockAlignment}
      brandCode={brandPortfolioName || brandCode || ''}
    >
      <div className={clsx('bannertext container-sm')} data-testid="banner-text-content" {...customAttributes}>
        {assetVariation === 'iconfont' && (
          <div className="bannertext_icon">
            <Icon iconClass={iconFontClasses} aria-live="polite" ariaLabel={iconAltText} />
          </div>
        )}
        {assetVariation === 'image' && renditions && (
          <div className="bannertext_img">
            <Image
              altText={dynamicMedia?.altText}
              renditions={renditions}
              dynamic={dynamicMedia?.dynamic}
              defaultImageURL={dynamicMedia?.assetPath}
            />
          </div>
        )}
        {eyebrow && <Eyebrow text={eyebrow} />}
        {handleHorizontalRulePlacement()}
        {descriptionLayout === 'standard' ? (
          <>
            {descriptionText && (
              <div
                className={clsx(
                  'bannertext_desccontent bannertext_text-align',
                  showShortDescription && showLinkText === SEE_MORE ? 'small-margin' : ''
                )}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: descriptionText }}
                  ref={BannerTextDescriptionRef}
                  className={clsx(
                    showShortDescription && showLinkText === SEE_MORE ? 'm-ellipsis-2lines' : '',
                    't-body-m'
                  )}
                ></div>
                {isShowMoreLinkHide && (
                  <Link
                    text={''}
                    linkClassName={clsx('t-label-alt-m bannertext_link')}
                    linkHref={''}
                    target="_blank"
                    callback={handleExpandButton}
                    tabIndex={0}
                    id={componentId}
                  >
                    <span className="bannertext_linktext" aria-live="polite">
                      {showLinkText}
                    </span>
                  </Link>
                )}
              </div>
            )}
            {ctaPrimary()}
            {ctaSecondary()}
          </>
        ) : (
          <div className={clsx('description-column')}>
            <div
              className={clsx('column', contentBlockAlignment === 'center' ? 'align-items-center' : 'align-items-left')}
            >
              {column1DescriptionText && (
                <div
                  className={clsx(
                    'bannertext_desccontent bannertext_text-align',
                    column1ShowShortDescription && column1ShowLinkText === SEE_MORE ? 'small-margin' : ''
                  )}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: column1DescriptionText }}
                    ref={Column1DescriptionRef}
                    className={clsx(
                      column1ShowShortDescription && column1ShowLinkText === SEE_MORE ? 'm-ellipsis-3lines' : '',
                      't-body-m'
                    )}
                  ></div>
                  {isColumn1ShowMoreLinkHide && (
                    <Link
                      text={''}
                      linkClassName={clsx('t-label-alt-m bannertext_link')}
                      linkHref={''}
                      target="_blank"
                      callback={handleExpandButtonColumn1}
                      tabIndex={0}
                      id={componentId}
                    >
                      <span className="bannertext_linktext" aria-live="polite">
                        {column1ShowLinkText}
                      </span>
                    </Link>
                  )}
                </div>
              )}
              {ctaColumn1()}
            </div>
            <div
              className={clsx('column', contentBlockAlignment === 'center' ? 'align-items-center' : 'align-items-left')}
            >
              {column2DescriptionText && (
                <div
                  className={clsx(
                    'bannertext_desccontent bannertext_text-align',
                    column2ShowShortDescription && column2ShowLinkText === SEE_MORE ? 'small-margin' : ''
                  )}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: column2DescriptionText }}
                    ref={Column2DescriptionRef}
                    className={clsx(
                      column2ShowShortDescription && column2ShowLinkText === SEE_MORE ? 'm-ellipsis-3lines' : '',
                      't-body-m'
                    )}
                  ></div>
                  {isColumn2ShowMoreLinkHide && (
                    <Link
                      text={''}
                      linkClassName={clsx('t-label-alt-m bannertext_link')}
                      linkHref={''}
                      target="_blank"
                      callback={handleExpandButtonColumn2}
                      tabIndex={0}
                      id={componentId}
                    >
                      <span className="bannertext_linktext" aria-live="polite">
                        {column2ShowLinkText}
                      </span>
                    </Link>
                  )}
                </div>
              )}
              {ctaColumn2()}
            </div>
          </div>
        )}
      </div>
    </StyledBannerText>
  );
};
